var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col w-full"},[(_vm.loadingWorkflows)?_c('div',{staticClass:"max-auto flex flex-col gap-5 justify-center items-center w-full h-80 rounded-lg ring-2-gray-500"},[_c('Loader'),_vm._v(" Loading Workflows... ")],1):_c('div',[(this.getWorkflowId)?_c('div',{staticClass:"justify-between flex-grow max-h-full overflow-y-auto"},[_c('div',{staticClass:"flex h-full gap-4 flex-1 overflow-y-auto"},[(_vm.currentComponent.name != 'createTriggerActions')?_c('div',{staticClass:"w-2/12 rounded-lg self-start bg-white shadow flex flex-col gap-x-4 overflow-y-auto scroll-bar"},_vm._l((_vm.navigation),function(nav,idx){return _c('div',{key:idx,staticClass:"px-4 py-3 transition-all relative duration-200 items-center text-sm font-medium flex gap-3 cursor-pointer",class:{
              ' bg-primary-300 hover:bg-primary-200 text-base-content-800':
                _vm.currentTab === nav.nav,
              'hover:bg-gray-100   hover:text-base-content-600':
                _vm.currentTab !== nav.nav,
              'border-t border-gray-400 border-0': idx != 0,
            },on:{"click":function($event){return _vm.handleCurrentTab(nav)}}},[_c('font-awesome-icon',{attrs:{"icon":[nav.iconPrefix || 'fa', nav.icon]}}),_vm._v(" "+_vm._s(nav.title)+" ")],1)}),0):_vm._e(),(_vm.currentTab)?_c('div',{class:_vm.isTriggersRoute
              ? 'flex-1 relative'
              : 'flex-1 relative bg-card-bg rounded-md shadow'},[(!_vm.isTriggersRoute)?_c('div',{staticClass:"px-4 lg:px-5 py-3 flex gap-3 items-center bg-primary-300 border-b border-gray-200 text-base-content-600 text-lg font-medium",style:(`max-height: 20vh;`)},[_c('span',{staticClass:"font-bold"},[_vm._v(_vm._s(_vm.TabLabel)+" ")])]):_vm._e(),_c('div',{class:_vm.isTriggersRoute ? '' : 'p-3 flex pb-4'},[_c(_vm.currentComponent,{tag:"component",on:{"saveAndNext":_vm.handleSaveAndNext}})],1)]):_vm._e()])]):_vm._e(),(!this.getWorkflowId && !_vm.isViewMode)?_c('div',{staticClass:"flex justify-center rounded-lg bg-white h-96 p-3"},[_c('div',{staticClass:"flex gap-2 w-full items-center justify-center rounded-lg bg-white"},[_c('div',{staticClass:"flex rounded-lg"},[_c('v-button',{staticClass:"py-2",attrs:{"text":"Add New","type":"primary"},on:{"click":_vm.handleAddDefault}})],1)])]):(_vm.isViewMode && !this.getWorkflowId)?_c('div',[_c('div',{staticClass:"w-full flex justify-center items-center flex-1 rounded-lg bg-white h-full"},[_c('Empty',{attrs:{"text":"There is no workflow attached to this package.","classList":"text-lg"}})],1)]):_vm._e(),_c('div',{staticClass:"flex flex-col gap-2"},[_c('modalContent',{ref:"workflowModal",staticClass:"modal-class",attrs:{"name":"workflowModal","title":"Add Workflow","max-width":400,"loadingGlobal":_vm.loadingWorkflowsModal,"show-footer":false},scopedSlots:_vm._u([{key:"content",fn:function(){return _vm._l((_vm.workflows),function(workflow){return _c('div',{key:workflow.id,staticClass:"h-12"},[_c('div',{staticClass:"flex p-1 justify-between"},[_c('span',[_vm._v(_vm._s(workflow.name))]),_c('v-button',{attrs:{"text":"Add","type":"primary"},on:{"click":function($event){return _vm.handleAddWorkflow(workflow)}}})],1),_c('div',{staticClass:"border-t border-gray-400 p-1"})])})},proxy:true}])})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }